<template>
   <span> {{ StandardMessage }} </span>
</template>

<script>
import { ref, reactive, onMounted, onBeforeMount } from '@vue/composition-api';
import axios from 'axios';
import URL from '../request';
import { toast_orign } from '@/utils/qToast';
export default {
   setup(props, { root }) {
      const StandardMessage = ref(
         "Votre abonnement est en cours de traitement..., viellez s'il vous plait patientez"
      );
      const StandardClass = ref('text-gray-700');

      onMounted(async () => {
         localStorage.removeItem('qResubscription') 
         const xGetToken = root.$route.query.token;
         console.log(xGetToken);
         try {
            const { data } = await axios.post(URL.ENVOI_TOKEN, {
               token: xGetToken,
            });
            if (data) {
               StandardClass.value = 'text-green-500';
               StandardMessage.value =
                  'Votre paiement à été effectuez avec succes';
               toast_orign(
                  root,
                  'Abonnement renouveler avec succès',
                  'CreditCardIcon',
                  StandardMessage.value,
                  'success',
                  'top-right'
               );
               setTimeout(() => {
                  location.assign('/');
               });
            }
         } catch (error) {
            StandardClass.value = 'text-red-500';
            StandardMessage.value =
               "Une erreur, c'est produite, Veuillez recharger la page ou contactez le service technique d'Ediqia";
            toast_orign(
               root,
               'Échec abonnement',
               'CreditCardIcon',
               StandardMessage.value,
               'danger',
               'top-right'
            );
         }
      });

      return {
         StandardMessage,
         StandardClass,
      };
   },
};
</script>
<style>
.checkbox:checked {
   right: 0;
   background-color: #4338ca;
}
</style>
